import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm';
import PhotoSwipeBase from 'photoswipe/dist/photoswipe.esm';

//Nav
document.querySelectorAll('nav a').forEach(link => {
    link.addEventListener('click', e => {
        e.preventDefault();
        const top = e.currentTarget.href.endsWith('#') ? 0 : document.querySelector(e.currentTarget.href.substring(e.currentTarget.href.indexOf('#'))).offsetTop;
        scrollTo({
            top,
            behavior: 'smooth',
        });
    });
});

const aboutSectionTop = document.querySelector('#about').offsetTop - 150;
const studioLetterEls = document.querySelectorAll('._title span');
const studioIconEl = document.querySelector('._title div');
document.addEventListener('scroll', scroll);
scroll();
function scroll() {
    let percent =  Math.max(0, Math.min(1.0, window.scrollY / aboutSectionTop));
    if (window.innerWidth < 768) {
        percent = 1.0;
    }
    
    for (let i = 0; i < studioLetterEls.length; i++) {
        studioLetterEls[i].style.opacity = Math.pow(percent / ((i + 1) / studioLetterEls.length), 4);
    }
    studioIconEl.style.rotate = `${percent * (135)}deg`;
    studioIconEl.style.opacity = Math.min(1, percent * 50);
}

//Galleries
new PhotoSwipeLightbox({
    gallery: '._gallery',
    children: 'a',
    tapAction: 'next',
    pswpModule: PhotoSwipeBase,
}).init();

//Contact form
document.querySelectorAll('input, textarea').forEach(node => {
    node.addEventListener('input', e => {
        e.currentTarget.classList.remove('_is-invalid');
    });
});

document.querySelector('button#submit').addEventListener('click', () => {
    let data = {
        name: document.querySelector('input#name').value,
        email: document.querySelector('input#email').value,
        msg: document.querySelector('textarea#msg').value
    };

    for (const [key, val] of Object.entries(data)) {
        if (val == '') {
            document.querySelector('#' + key).classList.add('_is-invalid');
        }
    }
    if (document.querySelectorAll('._is-invalid').length) {
        return;
    }

    document.querySelector('button#submit').textContent = "Submitting...";
    document.querySelector('button#submit').disabled = true;

    fetch('https://prod-04.eastus.logic.azure.com:443/workflows/3e22aa694e8746cf9da9343205429040/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=D4k6DybTv5HhmzRAfv57EfeMeERdoqoNtl33_kTWlF4',
    {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then(() => {
        document.querySelector('._contact-form').classList.add('hidden');
        document.querySelector('._contact-success').classList.remove('hidden');
    })
    .catch(() => {
        document.querySelector('._contact-form').classList.add('hidden');
        document.querySelector('._contact-error').classList.remove('hidden');
    })
    .finally(() => {
        document.querySelector('button#submit').textContent = "Submit";
        document.querySelector('button#submit').disabled = false;
    });
});